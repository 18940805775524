/*
Renkler:
Sarı: #ec6e00
Mavi: #106c84  - R:16 G:108 B:132
*/
body {
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #106c84;
  font-family: Avenir;
  font-size: 20px;
}

.BorderDefaultColor {
  border-color: #dddddd;
}

.BorderHighlightColor {
  border-color: #ec6e00;
}

.Page {
  padding: 20px 6%;
}

.PageCenter {
  padding: 20px 6%;
  text-align: center;
}

.PageBigImg {
  margin: 20px;
  width: 94%;
  height: auto;
}

.PageHeader {
  padding: 30px;
  text-align: center;
  color: #106c84;
  font-size: 32px;
  letter-spacing: 1px;
}

.PageHeaderAlt {
  color: #ec6e00;
}

.PageSubHeader {
  font-size: 18px;
  color: #808080;
}

.PageSection {
  font-size: 20px;
  margin: 20px;
  text-align: left;
}

.PageFAQ {
  color: #ec6e00;
  font-size: 24px;
  margin: 20px;
}

.PageSection img {
  float: left;
  margin: 0px;
  margin-right: 30px;
}

.PageBullet {
  font-size: 16px;
  margin: 20px;
  color: #808080;
}

ul.PageBullet {
  list-style-type: square;
  list-style-position: outside;
}

.PageText {
  margin: 20px;
  text-align: justify;
  font-size: 16px;
  color: #808080;
}

.PageTextCenter {
  margin: 20px;
  text-align: center;
  font-size: 16px;
  color: #808080;
}

.PageBold {
  margin: 20px;
  text-align: justify;
  font-size: 16px;
  color: #ec6e00;
}

.PageInput {
  display: inline-block;
  margin: 20px;
  text-align: left;
  font-size: 16px;
  color: #808080;
  width: auto;
}

.PageInputSmall {
  font-size: 12px;
  text-align: right;
  color: #808080;
}

.PageTimer {
  font-size: 24px;
  text-align: left;
  color: #808080;
}

/* Menu template */
.Menu {
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom:20px;
  margin: 0px;
  text-align: left;
  display: grid;
  grid-template-columns: 250px auto 80px;
  font-family: Georgia;
}

.MenuLogo {
  width: 200px;
}

.MenuLogo img {
  animation: rotation 2s infinite linear;
  animation-direction: alternate;
}

@keyframes rotation {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(17deg);
  }
}

.MenuContainer {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  padding-top: 32px;
  padding-left: 12px;
  padding-right: 0px;
  padding-bottom: 10px;
  font-size: 16px;
  text-align: center;
}

.MenuItem {
  color: #106c84;
  text-decoration: none;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.MenuItem:hover {
  color: #ec6e00;
  text-decoration: none;
}

.MenuRightContainer {
  padding-top: 26px;
  font-size: 16px;
  text-align: right;
}

.MenuShort {
  position: relative;
  display: inline-block;
  cursor: pointer;
  display: none;
  margin-right: 20px;
}

.MenuShortContent {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f1f1f1;
  min-width: auto;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
  border-radius: 10px;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
	filter: alpha(opacity=85);
	-moz-opacity:0.85;
	-khtml-opacity: 0.85;
	opacity: 0.85;
}

.MenuShortSubContent {
  display: none;
  position: absolute;
  right: 130px;
  top: -5px;
  background-color: #f1f1f1;
  min-width: auto;
  width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 110;
  border-radius: 10px;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
	filter: alpha(opacity=85);
	-moz-opacity:0.85;
	-khtml-opacity: 0.85;
	opacity: 0.85;
}

.MenuShortItem {
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.MenuShort:hover .MenuShortContent {display: block;}
.MenuShortItem:hover { color: #ec6e00;}
.MenuShortItem:hover + .MenuShortSubContent {display: block;}

.MenuIcon {
  width: 30px;
  margin-top: -2px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .MenuShort { display: block; }
  .MenuItem { display: none; }
}

.MenuSubContent {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f1f1f1;
  min-width: auto;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
  border-radius: 10px;
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
	filter: alpha(opacity=85);
	-moz-opacity:0.85;
	-khtml-opacity: 0.85;
	opacity: 0.85;
}

.MenuSubItem {
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.MenuSub:hover .MenuSubContent {display: block;}

.MenuSubItemItem {
  color: #106c84;
  text-decoration: none;
  padding-left: 5px;
  padding-right: 5px;
}

.MenuSubItemItem:hover {
  color: #ec6e00;
  text-decoration: none;
}

/* Header template */
.Header {
  width: 100%;
  letter-spacing: 2px;
  font-family: Cambria;
}

.HeaderText {
  background-color: #dddddd;
  color: #ec6e00;
  /* opacity */
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
	filter: alpha(opacity=65);
	-moz-opacity:0.65;
	-khtml-opacity: 0.65;
	opacity: 0.65;
}

.HeaderText1 {
  font-size: 3.5vw;
}

.HeaderText2 {
  font-size: 2vw;
}

.HImgNormal {
  display: block;
  height: auto;
}

.HImgMobile {
  display: none;
  height: auto;
}

@media (max-width: 768px) {
  .HImgNormal {display: none;}
  .HImgMobile {display: block;}
  .HeaderText1 {font-size: 5vw;}
  .HeaderText2 {font-size: 3.5vw;}
}

/* Header Sub */
.HeaderSub {
  position: relative;
  background-image: linear-gradient(to right, #106c84, #106c84);
  width: 100%;
  letter-spacing: 4px;
  font-family: Cambria;
}

.HeaderSubText {
  position: absolute;
  top: 60%;
  left: 10%;
  z-index: 20;
  color: white;
  font-size: 4vw;
  font-stretch: expanded;
}

.Deneme { /* HeaderSub.js içinde Deneme aşamasındadır mesajı için kullanılıyor, sonra silinecek */
  font-size: 12px;
  color: #ec6e00;
  text-align: right;
  margin: 0px 6%;
}

/* Cover */
.Cover {
  display: inline-block;
  text-align: center;
  font-family: Courier;
}

.CoverItem {
  float: left;
  border-style: solid;
  border-width: 1px;
  width: 250px;
  margin: 20px;
  cursor: pointer;
}

.CoverItemHeader {
  padding-top: 20px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 18px;
  height: 75px;
}

.CoverItem:hover {
  color:#ec6e00;
}

.CoverItemImage {
  text-align: center;
}

.CoverItemImage img {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  height: 190px;
}

.CoverItemText {
  padding-bottom: 20px;
  text-align: center;
  font-size: 14px;
}

/* Banner template */
.Banner {
  display: inline-block;
  background-image: linear-gradient(to right, #106c84, white);
  color: white;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-left: 6%;
  padding-right: 6%;
  font-family: Palatino;

  animation: slider 4s infinite linear;
}

.Banner img {
  float: right;
  padding-right: 20px;
  margin: 0px;
  padding: 0px;
  display: block;
}

.BannerHeader {
  text-align: center;
  font-size: 24px;
  padding: 10px;
  letter-spacing: 2px;
}

.BannerText {
  text-align: justify;
  font-size: 16px;
  padding: 20px;
}

/* ContactForm template */
.ContactForm {
  display: inline-block;
  background-image: url('./assets/contact.webp'), linear-gradient(to right, white, #ec6e00);
  background-repeat: no-repeat;
  color: #dddddd;
  padding: 0px 6%;
  text-align: center;
  width: 100%;
  font-family: charter;
}

.Contact1 {
  float: right;
}

@media (max-width: 940px) {
  .ContactForm {
    background-image: linear-gradient(to right, white, #ec6e00);
  }
}

.ContactHeader {
  color: white;
  font-size: 22px;
  padding-bottom: 4px;
}

.ContactText {
  font-size: 18px;
  padding-bottom: 5px;
}

.ContactField {
  padding-bottom: 5px;
  width: min(500px, 90vw);
  margin-left: auto;
  margin-right: auto;
}

.ContactButton {
  padding-bottom: 4px;
  width: min(500px, 90vw);
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

/* Footer template */
.Footer {
  display: inline-block;
  padding: 20px 6%;
  width: 100%;
}

.FooterLogo {
  float: left;
  text-align: left;
  margin-top: 20px;
  width: 140px;
}

.FooterLogo img {
  animation: rotate180 4s infinite linear;
  animation-direction: alternate;
}

@keyframes rotate180 {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(180deg);
  }
}

.FooterClaim {
  float: left;
  margin-top: 20px;
  font-size: 11px;
  text-align: justify;
  width: 250px;
}

.FooterCenter {
  float: left;
  text-align: left;
  margin: 20px 3%;
  font-size: 12px;
}

.FooterCenterHeader {
  margin-bottom: 12px;
  font-size: 14px;
}

.FooterCenterLink {
  cursor: pointer;
  color: #106c84;
}

.FooterCenterLink:hover {
  color: #ec6e00;
  text-decoration: none;
}

.FooterRight {
  float: right;
  margin: 0px;
  padding: 0px;
}

.FooterCredits {
  text-align: right;
  font-size: 12px;
  margin-right: 24px;
}

.FooterLinks {
  text-align: right;
  padding-top: 28px;
  padding-right: 20px;
}

.FooterLink {
  cursor: pointer;
  margin-left: 8px;
}

.Tail {
  display: inline-block;
  background-image: linear-gradient(to bottom, #106c84, white);
  width: 100%;
  color: white;
}

.TailSil {
  display: inline-block;
  background-image: linear-gradient(to bottom, #106c84, white);
  height: 224px;
  width: 100%;
  color: white;
}

.TailReserved {
  float: left;
  margin: 20px 6%;
  font-size:18px;
}

.TailReservedLight {
  color: #ec6e00;
}

.TailMenu {
  float: right;
  margin: 20px 6%;
  font-size: 18px;
}

.TailMenuItem {
  cursor: pointer;
  color: white;
  padding-left: 10px;
}

.TailMenuItem:hover {
  color: #ec6e00;
  text-decoration: none;
}

/* Page Table */
.PageTable {
  display: table;
}

.PageTableHeader {
  color: #106c84;
  font-size: 20px;
  margin: 20px;
}

.PageTableRow {
  display: table-row;
  color: #808080;
  font-size: 14px;
}

.PageTableRowHeader {
  display: table-row;
  color: #ec6e00;
  font-size: 14px;
}

.PageTableCell {
  display: table-cell;
  padding-left: 20px;
  padding-right: 20px;
}

.MyButton {
  border-radius: 4px;
  text-decoration: none;
  padding-top: 11px;
  padding-bottom: 10px;
  padding-left: 13px;
  padding-right: 13px;
  font-size: 16px;
  letter-spacing: normal;
}

.MyButton:hover {
  text-decoration: none;
}

/* Spinner */
.Warning {
  text-align: center;
  margin: 0px;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.w_top {
  background-image: linear-gradient(to top, white, #ec6e00);
}

.w_bottom {
  background-image: linear-gradient(to bottom, white, #ec6e00);
}

.Warning img {
  width: 60px;
  animation: rotate360 1.5s infinite linear;
}

@keyframes rotate360 {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.cursorPointer {
  cursor: pointer;
}

.Turuncu {
  color: #ec6e00;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #106c84;
}
.custom-control-input:checked~.custom-control-label::before {
  color: white;
  background-color: #106c84;
}

/* SoruBar */
.SoruBar {
  text-align: center;
}

.SoruCevapli {
  color: #106c84;
}

.SoruDogruCevapli {
  color: #ec6e00;
}

.SoruCevapsiz {
  color: #808080;
}

.SoruGoruntulenen {
  font-size: 34px;
  vertical-align: middle;
  margin-left: 7px;
  margin-right: 7px;
  cursor: pointer;
}

.SoruDiger {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

/* SoruAvatar */
.Soru {
  margin: 20px;
}

.SoruHeader {
  font-size: 26px;
  color: #ec6e00;
  text-align: center;
  font-family:Georgia, 'Times New Roman', Times, serif;
}

.SoruZorluk {
  text-align: right;
  font-size: 11px;
  color: #808080;
}

.ZorlukO {
  color: green;
}

.ZorlukU {
  color: darkorange;
}

.ZorlukB {
  color: red;
}

.SoruPage {
  padding: 20px 6% 1px 6%;
}

.SoruCizgi {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #D0D0D0;
}

.SoruTable {
  display: table;
  margin: 0px 20px 15px 20px;
  font-size: 16px;
  color: #808080;
}

.SoruTableRow {
  display: table-row;
  color: #808080;
  font-size: 18px;
  cursor: pointer;
}

.SoruTableCell {
  display: table-cell;
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer;
}

.SoruIpucu {
  text-align: right;
  font-size: 11px;
  color: #ec6e00;
  cursor: pointer;
}

/* CevapAnahtari */
.CevapAnahtari {
  display: table;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  margin: 20px;
  color: #808080;
}

.CevapAnahtariBaslik {
  display: table-row;
  color: #106c84;
  font-size: 18px;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #808080;
}

.CevapAnahtariSatirBaslik {
  display: table-row;
  color:lightslategrey;
  font-size: 18px;
}

.CevapAnahtariSatir {
  display: table-row;
  color: #808080;
  font-size: 16px;
  cursor: pointer;
}

.CevapAnahtariSatir:hover {
  background-color: lightblue;
}

.CevapAnahtariHucre {
  display: table-cell;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  border-style: dotted;
  border-width: 1px;
  border-color:lightgray;
}
/* A4 size 
#docToPrint {
  width: 595px;
  height: 842px;
}
*/

.Counter {
  text-align: center;
}